import api from './api'

const findActive = () => {
  return api.get('news/active')
}

const findById = (id) => api.get(`news/find-by-id/${id}`)
const findBySlug = (slug) => api.get(`news/find-by-slug/${slug}`)

const findActiveExternalNews = () => api.get(`news/active-external`);
const externalBySlug = (slug) => api.get(`news/external-by-slug/${slug}`);

export { findActive, findById, findBySlug, findActiveExternalNews, externalBySlug };
